import React, {useRef, useState, useEffect, useCallback, useMemo} from 'react';
import '@syncfusion/ej2-base/styles/material.css';
import '@syncfusion/ej2-buttons/styles/material.css';
import '@syncfusion/ej2-inputs/styles/material.css';
import '@syncfusion/ej2-popups/styles/material.css';
import '@syncfusion/ej2-react-calendars/styles/material.css';
import '@syncfusion/ej2-react-schedule/styles/material.css';
import '@syncfusion/ej2-navigations/styles/material.css';
import './ScheduleView.scss';
import {
    ScheduleComponent, Month, Inject, DragAndDrop, Resize, ViewsDirective, ViewDirective, Day, Week
} from '@syncfusion/ej2-react-schedule';
import {useDispatch, useSelector} from 'react-redux';
import {message, Modal} from 'antd';
import {deleteBooking, deleteEvents, refreshTimeline, updateSchedule} from '../../../services/timeline';
import moment from 'moment';
import 'moment-timezone';
import {ApiTimelineItemType, SidePanelItemType, SyncfusionLicenseKey, ViewType} from '../../../constants';
import {setSelected} from "../../../redux/reducers/mainTable";
import {DeleteOutlined} from "@ant-design/icons";
import {registerLicense} from "@syncfusion/ej2-base";
import {openNew, setTemporaryDates} from "../../../redux/reducers/detailsPanel";
import useDateTimeFormat from "../../../hooks/useDateTimeFormat";
import {LocationConflicts} from "../../Timeline/TimelineTable";
import {TagIcon} from "../../../helpers/Tags";
import {QuickInfoContentTemplate} from "../QuickInfoTemplate/QuickInfoContentTemplate";
import {
    adjustEndDateIfMidnight, isInvalidTime, isSingleDay,
} from "../../../helpers/time";
import QuickInfoHeaderTemplate from "../QuickInfoHeaderTemplate/QuickInfoHeaderTemplate";
import WithQuickInfoHeaderProps from "../QuickInfoHeaderTemplate/WithQuickInfoHeaderProps";
import {setCurrentView} from "../../../redux/reducers/timeline";

const ScheduleView = ({items, loadItemToEdit, onOpenCloneEventModal}) => {
    registerLicense(SyncfusionLicenseKey);

    let scheduleObj = useRef(null);
    const dispatch = useDispatch();
    const currentView = useSelector((state) => state.timeline.currentView);
    const [selectedEvents, setSelectedEvents] = useState([]);
    const dateTimeFormats = useDateTimeFormat();
    const activePortfolio = useSelector((state) => state.projects.activePortfolio);
    const [keyToForceReRenderOfCalendar, setKeyToForceReRenderOfCalendar] = useState(Date.now());
    const timeZoneMappings = useSelector((state) => state.infrastructure.timeZoneMappings);
    const selectedTimeZone = useSelector((state) => state.timeline.selectedTimeZone);
    const {startDate, endDate} = useSelector((state) => state.timeline);
    const projects = useSelector(state => state.projects?.projects);
    const selectedProjects = useSelector((state) => state.projects.activeProjects);
    const projectArray = Array.isArray(projects) ? projects : [];

    const calculateInterval = (startDate, endDate) => {
        if (!startDate || !endDate || !moment(startDate).isValid() || !moment(endDate).isValid()) {
            console.error("Invalid startDate or endDate");
            return 0;
        }

        const start = moment(startDate);
        const end = moment(endDate);

        if (start.year() === end.year() && start.month() === end.month()) {
            return 1;
        }

        const diff = end.diff(start, 'months');

        if (diff === 0 && (start.month() !== end.month() || start.year() !== end.year())) {
            return 2;
        }

        return diff + 1;
    };

    const [interval, setInterval] = useState(calculateInterval(startDate, endDate));

    useEffect(() => {
        const newInterval = calculateInterval(startDate, endDate);

        setInterval(newInterval);
        setKeyToForceReRenderOfCalendar(Date.now());

    }, [startDate, endDate]);

    useEffect(() => {
        document.addEventListener('mouseup', mouseupHandler);
        return () => {
            document.removeEventListener('mouseup', mouseupHandler);
        };
    }, []);

    useEffect(() => {
        const scheduleElement = scheduleObj.current?.element;

        if (!scheduleElement) return;

        let previousWidth = scheduleElement.offsetWidth;
        let previousHeight = scheduleElement.offsetHeight;

        const handleResize = () => {
            const currentWidth = scheduleElement.offsetWidth;
            const currentHeight = scheduleElement.offsetHeight;

            if (currentWidth !== previousWidth || currentHeight !== previousHeight) {
                setKeyToForceReRenderOfCalendar(Date.now());
                previousWidth = currentWidth;
                previousHeight = currentHeight;
            }
        };

        const resizeObserver = new ResizeObserver(handleResize);
        resizeObserver.observe(scheduleElement);

        return () => {
            resizeObserver.disconnect();
        };
    }, []);

    const getCellContent = (date) => {
        const day = date.getDate();
        const month = date.toLocaleString('default', {month: 'short'});
        const year = date.getFullYear();

        const formattedDate = day === 1 ? `<div class="e-date-header e-navigate">${day}</div> <span class="date-highlight">${month} ${year}</span>` : `<div class="e-date-header e-navigate">${day}</div> <span class="date-highlight">${month}</span>`;

        return `<div class="date-row">${formattedDate}</div>`;
    };

    const onRenderCell = (args) => {

        const start = new Date(startDate);
        const end = new Date(endDate);

        start.setHours(0, 0, 0, 0);
        end.setHours(0, 0, 0, 0);

        if (args.date < start || args.date > end) {
            args.element.classList.add('e-disable-dates');
        }
    };

    const isMidnight = (timeString) => {
        const time = moment(timeString, 'HH:mm:ss');
        return time.hours() === 0 && time.minutes() === 0 && time.seconds() === 0;
    };

    const mouseupHandler = (e) => {
        if (!scheduleObj.current || !scheduleObj.current.element || !e.target) {
            return;
        }

        const target = e.target.classList.contains('e-work-cells');
        const selectedCells = scheduleObj.current.element.querySelectorAll(".e-selected-cell");

        if (selectedCells.length > 1 && target) {
            const hasDisabledCell = Array.from(selectedCells).some(cell => cell.classList.contains('e-disable-dates'));
            if (hasDisabledCell) {
                return;
            }

            const activeCellsData = scheduleObj.current.getCellDetails(scheduleObj.current.getSelectedElements());

            handleCellClick({
                startTime: activeCellsData.startTime, endTime: activeCellsData.endTime, element: e.target
            });
        }
    };

    const getEventOrBooking = (selected) => (selected.ItemType === ApiTimelineItemType.Booking ? "booking" : "event");
    const getEventsOrBookings = (selected) => (selected.ItemType === ApiTimelineItemType.Booking ? "bookings" : "events");
    const getProject = (id) => projects.find((p) => p.Id === id);

    const mappedData = useMemo(() => {
        return items.map(event => {
            const startTime = event.StartDateTime ? new Date(event.StartDateTime) : null;
            let endTime = event.EndDateTime ? new Date(event.EndDateTime) : null;

            const project = projectArray.find(x => x.Id === event.ProjectId);
            const projectTimeZoneId = project ? project.TimeZoneId : null;
            const projectColor = project ? project.Color : null;
            const projectShortName = selectedProjects.length === 1 ? null : project?.ShortName;

            if (isMidnight(startTime) && isMidnight(endTime)) {
                endTime = moment(endTime).add(1, 'day').toDate();
            }

            return {
                Id: event.Id,
                Subject: projectShortName ? `${event.Name} [${projectShortName}]` : event.Name,
                StartTime: startTime,
                EndTime: endTime,
                Duration: event.Duration,
                Notes: event.Notes,
                StartTimeOrigin: startTime,
                EndTimeOrigin: endTime,
                IsAllDay: event.IsAllDay,
                ItemType: event.ItemType,
                IsFavorite: event.IsFavorite,
                ProjectId: event.ProjectId,
                ProjectTimeZoneId: projectTimeZoneId,
                ProjectColor: projectColor,
                ProjectShortName: projectShortName,
                ProjectFullName: project?.Name,
                Locations: event.Locations || [],
                LocationConflicts: event.LocationConflicts || [],
                PeopleOrganizations: event.PeopleOrganizations || [],
                LabourLines: event.LabourLines || [],
                CategoryTags: event.CategoryTags || [],
                DepartmentTags: event.DepartmentTags || [],
                StatusTag: event.StatusTag || null,
            };
        });
    }, [items]);

    const getDeleteConfirmationTitle = (selected) => {
        if (selected.length === 1) {
            return `Are you sure you want to delete this ${getEventOrBooking(selected[0])} from ${getProject(selected[0].ProjectId)?.Name || ""}?`;
        } else {
            return `Are you sure you want to delete ${selected.length} ${getEventsOrBookings(selected[0])}?`;
        }
    };

    const getEventDetails = (args) => {
        const projectTimeZoneId = timeZoneMappings[args.data.ProjectTimeZoneId] || "America/New_York";
        const timelineSelectedTimeZoneId = timeZoneMappings[selectedTimeZone] || "America/New_York";

        const startTime = moment(args.data.StartTime);
        const endTime = moment(args.data.EndTime);

        return {projectTimeZoneId, timelineSelectedTimeZoneId, startTime, endTime};
    };

    const getDatesPayload = (startDate, endDate, projectTimeZoneId, selectedTimeZoneId) => {
        if (isMidnight(startDate) && isMidnight(endDate)) {
            return {
                StartDate: startDate.format('YYYY-MM-DD'),
                EndDate: endDate.format('YYYY-MM-DD'),
                StartTime: null,
                EndTime: null,
            };
        }

        const startInSelectedTimeZone = moment.tz({
            year: startDate.year(),
            month: startDate.month(),
            date: startDate.date(),
            hour: startDate.hour(),
            minute: startDate.minute(),
            second: startDate.second()
        }, selectedTimeZoneId);

        const endInSelectedTimeZone = moment.tz({
            year: endDate.year(),
            month: endDate.month(),
            date: endDate.date(),
            hour: endDate.hour(),
            minute: endDate.minute(),
            second: endDate.second()
        }, selectedTimeZoneId);

        const startTimeConverted = startInSelectedTimeZone.tz(projectTimeZoneId).format('HH:mm:ss');
        const endTimeConverted = endInSelectedTimeZone.tz(projectTimeZoneId).format('HH:mm:ss');

        const startDateConverted = startInSelectedTimeZone.tz(projectTimeZoneId).format('YYYY-MM-DD');
        const endDateConverted = endInSelectedTimeZone.tz(projectTimeZoneId).format('YYYY-MM-DD');

        return {
            StartDate: startDateConverted,
            EndDate: endDateConverted,
            StartTime: isMidnight(startTimeConverted) ? null : startTimeConverted,
            EndTime: isMidnight(endTimeConverted) ? null : endTimeConverted,
        };
    };

    const updateEventDateAndTime = (args, datesPayload) => {

        const updatePayload = {
            Id: args.data.Id, CurrentProjectId: args.data.ProjectId, ...datesPayload,
        };

        updateSchedule(updatePayload)
            .then((response) => {
                refreshTimeline(ApiTimelineItemType.Task);
            })
            .catch((err) => {
                const errorText = err.response?.data?.message || 'An error occurred';
                message.error(errorText);
            });
    };

    const setFromOriginTime = (startDate, endDate, args) => {
        let startTimeOrigin = moment(args.data.StartTimeOrigin);
        let endTimeOrigin = moment(args.data.EndTimeOrigin);

        startDate.set({
            hour: startTimeOrigin.hour(),
            minute: startTimeOrigin.minute(),
            second: startTimeOrigin.second(),
            millisecond: startTimeOrigin.millisecond()
        });

        endDate.set({
            hour: endTimeOrigin.hour(),
            minute: endTimeOrigin.minute(),
            second: endTimeOrigin.second(),
            millisecond: endTimeOrigin.millisecond()
        });

        return {startDate, endDate};
    };

    const adjustEndDateForResize = (startDate, endDate, args) => {
        if (args.data.IsAllDay) {
            endDate = endDate.subtract(1, 'days');
        } else {
            const isResizedFromLeft = startDate.hour() === 0 && startDate.minute() === 0 && startDate.second() === 0;
            if (!isResizedFromLeft) {
                endDate = endDate.subtract(1, 'day');
            }
        }
        return endDate;
    };

    const handleResizeStop = (args) => {
        const {projectTimeZoneId, timelineSelectedTimeZoneId, startTime, endTime} = getEventDetails(args);

        let startDate = startTime;
        let endDate = endTime;

        if (currentView === ViewType.Month) {
            endDate = adjustEndDateForResize(startDate, endDate, args);

            const result = setFromOriginTime(startDate, endDate, args);
            startDate = result.startDate;
            endDate = result.endDate;
        }

        const datesPayload = getDatesPayload(startDate, endDate, projectTimeZoneId, timelineSelectedTimeZoneId);

        updateEventDateAndTime(args, datesPayload);
    };

    const handleDragStop = (args) => {
        const {projectTimeZoneId, timelineSelectedTimeZoneId, startTime, endTime} = getEventDetails(args);

        let endDate = endTime;
        endDate = adjustEndDateIfMidnight(endDate);

        const datesPayload = getDatesPayload(startTime, endDate, projectTimeZoneId, timelineSelectedTimeZoneId);

        updateEventDateAndTime(args, datesPayload);
    };

    const handleDeleteClick = (args) => {

        let selected;
        let dataObj = args.data;

        if (typeof dataObj === 'object' && dataObj !== null && !Array.isArray(dataObj)) {
            const hasNumericalKeys = Object.keys(dataObj).every(key => !isNaN(key));
            if (!hasNumericalKeys) {
                selected = [args.data];
            } else {
                selected = Object.values(args.data).flat()
            }
        }

        Modal.confirm({
            title: getDeleteConfirmationTitle(selected),
            icon: <DeleteOutlined/>,
            content: "You cannot undo this action",
            className: "delete-contact-modal",
            okText: "Delete",
            okButtonProps: {danger: true},
            async onOk() {
                const ids = selected.map((b) => b.Id);

                if (selected[0].ItemType === ApiTimelineItemType.Task) {
                    await deleteEvents(ids);
                } else if (selected[0].ItemType === ApiTimelineItemType.Booking) {
                    await deleteBooking(ids);
                }

                await refreshTimeline(selected[0].ItemType);

                if (selected.length === 1) {
                    message.success(`${selected.length} ${selected[0].ItemType === ApiTimelineItemType.Booking ? "booking" : "event"} was deleted.`);
                } else {
                    message.success(`${selected.length} ${selected[0].ItemType === ApiTimelineItemType.Booking ? "bookings" : "events"} were deleted.`);
                }

                dispatch(setSelected([]));
            },
            onCancel() {
            },
        });
    };

    const handleEventDoubleClick = (args) => {

        if (!args.element || args.target.classList.contains('e-disable-dates')) {
            return;
        }

        loadItemToEdit({
            Id: args.data.Id, Name: args.data.Subject, ProjectId: args.data.ProjectId, ItemType: args.data.ItemType,
        });
    };

    const handleEditClick = ({Id, Subject, ProjectId, ItemType}) => {
        loadItemToEdit({
            Id: Id, Name: Subject, ProjectId: ProjectId, ItemType: ItemType,
        });
    };

    const handleEventClick = (args) => {
        if (args.event.ctrlKey) {
            const alreadySelected = selectedEvents.find(event => event.Id === args.data.Id);
            if (alreadySelected) {
                setSelectedEvents(selectedEvents.filter(event => event.Id !== args.data.Id));
            } else {
                setSelectedEvents([...selectedEvents, args.data]);
            }
        } else {
            setSelectedEvents([args.data]);
        }
    };

    const handleCellClick = (args) => {

        args.cancel = true;

        if (!args.element || args.element.classList.contains('e-disable-dates')) {
            return;
        }

        const type = SidePanelItemType.Event;

        const projectTimeZoneId = timeZoneMappings[selectedTimeZone] || "America/New_York";
        const timelineSelectedTimeZoneId = timeZoneMappings[selectedTimeZone] || "America/New_York";

        let endDate = moment(args.endTime);
        endDate = adjustEndDateIfMidnight(endDate);

        const datesPayload = getDatesPayload(moment(args.startTime), endDate, projectTimeZoneId, timelineSelectedTimeZoneId);

        setTimeout(() => {
            dispatch(openNew({type}));

            dispatch(setTemporaryDates({
                StartDate: datesPayload.StartDate,
                EndDate: datesPayload.EndDate,
                StartTime: datesPayload.StartTime,
                EndTime: datesPayload.EndTime,
            }));
        }, 100);
    };

    const handleCloneClick = (record) => {
        onOpenCloneEventModal(record);
    };

    const handlePopupOpen = (args) => {
        if (args.type === 'Editor') {
            args.cancel = true;
            handleEventDoubleClick(args);
        } else if (args.type === 'DeleteAlert') {
            args.cancel = true;
            handleDeleteClick(args);
        }
    };

    const handleActionComplete = (args) => {
        if (args.requestType === 'viewNavigate' || args.requestType === 'dateNavigate') {
            if (scheduleObj.current.currentView === ViewType.Day) {
                dispatch(setCurrentView(ViewType.Day));
            }
        }
    };

    const onEventRendered = (args) => {
        applyCategoryColor(args, scheduleObj.current?.currentView);
    };

    const applyCategoryColor = (args, currentView) => {
        if (args.data.ProjectColor) {
            args.element.style.backgroundColor = args.data.ProjectColor;
            args.element.style.color = 'rgba(0,0,0,.851)';
        }
    };

    const getEventTime = (time, format) => time ? moment(time).format(format) : null;

    const getEventNameClass = (isFavorite, currentView) => isFavorite && (currentView === ViewType.Month || currentView === ViewType.Day || currentView === ViewType.Week) ? 'favorite' : '';

    const renderLocations = (locations, props, projects) => locations?.map((location) => (
        <LocationConflicts key={location?.ContactId} item={props} projects={projects}
                           specificLocationContactId={location?.ContactId}/>));

    const renderStatusTagIcon = (statusTag) => statusTag?.Icon && <TagIcon icon={statusTag?.Icon}/>;

    function onDrag(args) {
        const draggingStartTime = getEventTime(args['startTime'], dateTimeFormats.time);
        const draggingEndTime = getEventTime(args['endTime'], dateTimeFormats.time);
        const eventElement = document.querySelector('.e-schedule-event-clone.e-drag-clone');
        const timeElement = eventElement.querySelector('.time');

        const isEventTimeNotSet = isInvalidTime(draggingStartTime) && isInvalidTime(draggingEndTime);

        if (isEventTimeNotSet) {
            timeElement.textContent = '';
        } else if (draggingStartTime === draggingEndTime) {
            timeElement.textContent = draggingStartTime;
        } else {
            timeElement.textContent = `${draggingStartTime} - ${draggingEndTime}`;
        }

        if (timeElement) {
            timeElement.innerHTML = timeElement.textContent;
        }
    }

    function onResize(args) {
        const resizeStartTime = getEventTime(args['startTime'] ? args['startTime'] : args.data.startTime, dateTimeFormats.time);
        const resizeEndTime = getEventTime(args['endTime'] ? args['endTime'] : args.data.endTime, dateTimeFormats.time);
        const eventElement = document.querySelector('.e-schedule-event-clone.e-resize-clone');
        const timeElement = eventElement.querySelector('.time');

        timeElement.textContent = `${resizeStartTime} - ${resizeEndTime}`;

        if (timeElement) {
            timeElement.innerHTML = timeElement.textContent;
        }
    }

    const formatEventTimeOnly = (startTime, endTime, dateTimeFormats) => {
        const startTimeFormatted = startTime ? moment(startTime).format(dateTimeFormats.time) : null;
        const endTimeFormatted = endTime ? moment(endTime).format(dateTimeFormats.time) : null;

        const isEventTimeNotSet = isInvalidTime(startTimeFormatted) && isInvalidTime(endTimeFormatted);

        if (isEventTimeNotSet) {
            return '';
        }

        if (startTimeFormatted === endTimeFormatted) {
            return startTimeFormatted;
        }

        return `${startTimeFormatted} - ${endTimeFormatted}`;
    };

    const dayAndWeekEventTemplate = (props) => {

        const time = formatEventTimeOnly(props.StartTime, props.EndTime, dateTimeFormats);
        const eventNameClass = getEventNameClass(props.IsFavorite, currentView);

        return (
            <div className="day-event-template-wrap">
                <div className="event-details-inline">
                    {renderStatusTagIcon(props.StatusTag)}
                    <span className="small-margin-right"></span>
                    {renderLocations(props.Locations, props, projects)}
                    <span className="small-margin-right"></span>
                    <div className={`subject ${eventNameClass}`}>{props.Subject}</div>
                </div>
                <div className="time">{time}</div>
            </div>
        );
    };

    const monthEventTemplate = (props) => {
        const startTime = getEventTime(props.StartTime, dateTimeFormats.time);
        const endTime = getEventTime(props.EndTime, dateTimeFormats.time);

        const isEventTimeNotSet = isInvalidTime(startTime) && isInvalidTime(endTime);
        const isSingleDayEvent = isSingleDay(props.StartTime, props.EndTime);

        const eventNameClass = getEventNameClass(props.IsFavorite, currentView);

        return (<>
            {!isEventTimeNotSet && <div className="e-time">{startTime}</div>}
            <div className={`e-subject e-text-center ${eventNameClass}`}>
                {renderStatusTagIcon(props.StatusTag)}
                <span className="small-margin-right"></span>
                {renderLocations(props.Locations, props, projects)}
                <span className="small-margin-right"></span>
                {props.Subject}
            </div>
            {!isEventTimeNotSet && !isSingleDayEvent && <div className="e-time">{endTime}</div>}
        </>);
    }

    const customCellTemplate = useCallback((props) => {
        if (props.type === "monthCells") {
            return (<div dangerouslySetInnerHTML={{__html: getCellContent(props.date)}}></div>);
        }
        return null;
    }, []);

    const majorSlotTemplate = useMemo(() => (props) => {
        const formattedDate = moment(props.date).format(dateTimeFormats.time);
        return (<div>{formattedDate}</div>);
    }, [dateTimeFormats.time]);

    const minorSlotTemplate = useMemo(() => (props) => {
        const formattedDate = moment(props.date).format(dateTimeFormats.time);
        return (<div>{formattedDate}</div>);
    }, [dateTimeFormats.time]);

    const timeScale = useMemo(() => ({
        enable: true, majorSlotTemplate: majorSlotTemplate.bind(this), minorSlotTemplate: minorSlotTemplate.bind(this)
    }), [majorSlotTemplate, minorSlotTemplate]);

    // const headerIndentTemplate = useMemo(() => (props) => {
    //     if (!props) {
    //         return null;
    //     }
    //
    //     return props.type === 'emptyCells' ? (<div className="header-template-wrap">All Day</div>) : null;
    // }, []);

    return (<div className="schedule-view">
            <ScheduleComponent
                key={keyToForceReRenderOfCalendar}
                ref={scheduleObj}
                //cssClass="all-day-customize"
                eventSettings={{
                    dataSource: mappedData,
                    spannedEventPlacement: 'TimeSlot',
                    allowDragAndDrop: true,
                    allowResizing: true,
                    minimumEventDuration: 30,
                }}
                actionComplete={handleActionComplete}
                firstDayOfWeek={activePortfolio?.DateTimeSettings?.StartWeekOn?.Value ?? 1}
                currentView={currentView}
                selectedDate={startDate ? startDate : undefined}
                width='100%'
                height='100%'
                className="full-height"
                dragStop={handleDragStop}
                resizeStop={handleResizeStop}
                resizing={currentView === ViewType.Day || currentView === ViewType.Week ? onResize : undefined}
                drag={currentView === ViewType.Day || currentView === ViewType.Week ? onDrag : undefined}
                renderCell={onRenderCell}
                rowAutoHeight={true}
                eventClick={handleEventClick}
                cellClick={handleCellClick}
                eventRendered={onEventRendered}
                timeScale={timeScale}
                cellTemplate={currentView === ViewType.Month ? customCellTemplate : undefined}
                quickInfoTemplates={{
                    header: WithQuickInfoHeaderProps(QuickInfoHeaderTemplate, {
                        handleEditClick, handleCloneClick, handleDeleteClick, scheduleObj
                    }), content: QuickInfoContentTemplate
                }}
                // headerIndentTemplate={headerIndentTemplate}
                popupOpen={handlePopupOpen}
                showHeaderBar={currentView === ViewType.Day || currentView === ViewType.Week}
                minDate={startDate ? new Date(startDate) : undefined}
                maxDate={endDate ? new Date(endDate) : undefined}
            >
                <ViewsDirective>

                    <ViewDirective option={ViewType.Day}
                                   eventTemplate={dayAndWeekEventTemplate}/>

                    <ViewDirective option={ViewType.Week}
                                   eventTemplate={dayAndWeekEventTemplate}/>

                    <ViewDirective option={ViewType.Month}
                                   eventTemplate={monthEventTemplate}
                                   interval={interval}/>
                </ViewsDirective>
                <Inject services={[Day, Week, Month, DragAndDrop, Resize]}/>
            </ScheduleComponent></div>
    );
};

export default ScheduleView;